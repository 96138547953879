span.bp3-popover-target{
    display: inherit;
}

.bp3-button-group .bp3-button{
    min-height: 0px;
    min-width: 0px;
    padding: 0px 10px 0px 10px;
    margin: 0 0 0 0;
}

.bp3-transition-container{
    z-index: 90071992547 !important;
}

.bp3-overlay{
    z-index: 90071992547 !important;   
}