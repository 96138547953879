.container_box{
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    /*box-shadow: 5px 5px 4px gray;*/
}

.containerhighlight{
    box-shadow: 0 0 5px 2px gold;
}

.node_body{
    display: flex;    
    flex: 5;
    flex-direction: column;
    padding: 0px 5px 2px 5px ;
    width: 100%;
}

.fix_node_width{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 325px !important;
}

.container_title{
    display: flex;
    flex: 1;
    justify-content: center;
    /*padding: 5px;*/
}

.container_icon{
    background-color: transparent;
}
.container_icon > img {
    height: 15px;
    width: 15px;
}

.container_title_and_citation{
    width:100%;
    display: flex;
    flex-direction: column;
}

.container_title_text {
    width:100%;
    flex: 3;
    display:flex;
}

.container_title_text > textarea { 
    border: none;
    text-align-last: center;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.container_citation_text{
    width: 100%;
    flex: 1;
    display:flex;
}

.container_citation_text > textarea{
    border: none;
    text-align-last: center;
    font-size: 8px;
    font-weight: bold;
    color:gray;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.container_graph_button{
    margin-top:1.5px;

    height: 15px !important;
    width: 15px !important;
    min-height: 15px !important;
    min-width: 15px !important;
}

.container_title_menu {
    display: flex;
    flex-direction: row;
}

.node_icon_column{
    display: flex;
    justify-content: space-around;
    width: 15px;
    height: 15px;
}

.node_children_column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top:1.5px;
}