.all{
    display: flex;
    flex-direction: column;
    height: 2160px;
    width: 3840px;
}

.workspace{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100%;
    width: 100%;
}


.edges{
    height: 2160px;
    width: 3840px;
    position: absolute;
    /*z-index: 1000000;*/
    pointer-events:none;
    overflow: visible;
}

textarea {
    resize: none;
    overflow: hidden;
    text-align: center;
}

textarea:focus {
    outline: none;
}