.or_container_frame{
    border: .1px solid black;
    background: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    /*border-radius: 2rem;*/
    border-radius: 11px;
    /*padding: 5px;*/
    /*margin-bottom: 7.5px;
    margin-top: 7.5px;*/
    /*box-shadow: 3px 3px 2px gray;*/
    position:relative;
    z-index:2;
}

.or_container:last-child{
    /*margin-bottom: 5px;*/
}

.or_node{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.or_node_element_menu{
    /*flex:1;*/
}
.or_node_nested {
    display: flex;
    flex:6;
    flex-direction: column;
    align-items: center;
    padding: 2px;
}


.or_node_nested .react-draggable:nth-of-type(2) .and_container_frame{
    margin-top: 4px;
 }

.or_node_nested .react-draggable:last-of-type .and_container_frame{
   margin-bottom: 4px;
}

textarea{
    background-color: transparent;
}
