@import './dnd_button.css';

.top_bar_main{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 32px;
    width: 100vw;
    background-color: white;
    border-bottom: 1px solid #CED9E0;
}

.top_bar_main .bp3-button{
    padding: 0 0 0 0;
}

.top_bar_nav{
    padding: 0 !important;
    z-index: 90071992547 !important;
}
.bp3-navbar {
    height: 20px !important;
}

.top_bar_file_ops{
    display: flex;
    flex-direction: column;
    flex: 1;
}

.top_bar_file_ops_files{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    align-items: center;
}

.top_bar_file_ops_add_node{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    align-items: center;
}

.top_bar_modes{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 2px;
    border-left: 1px solid #CED9E0;
}

.top_bar_modes_zoom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.top_bar_zoom_percentage{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.top_bar_modes_modes{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex: 1;
}

.top_bar_containers{
    display: flex;
    /*flex-direction: column;*/
    flex: 3.5;
    border-left: 1px solid #CED9E0;
    border-right: 1px solid #CED9E0;
    /* background-color: darkorchid; */
}

.top_bar_containers_main{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    /* background-color: gray; */
}

.top_bar_equal_space{
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1;
}


.top_bar_equal_space .bp3-button {
    min-height: unset;
}

.top_bar_max_width{
    width: 100%;
    height: 100%;
    margin: 1px;
}

.top_bar_containers_secondary{
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-around;
    align-items: center;
    /* background-color: silver; */
}

@media print{
    
    .noprint{
        display:none;
    }
 }