.subtitle_node_thick_line{
    border-top: 1px solid;
    margin-top: 5px;
    width:100%;
}

.subtitle_node_frame{
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 0px 2px 2px 3px; 
    height:auto;
    width:100%;
    position:relative;
    z-index: 1;
}

.subtitle_node_text {
    flex:3;
    display:flex;
    width:100%;
}

.subtitle_node_text > textarea {
    border: none;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.subtitle_node_citation {
    flex:1;
    display: flex;
    width:100%;
}

.subtitle_node_citation > textarea {
    border: none;
    text-align-last: center;
    font-size: 7px;
    font-weight: bold;
    color:gray;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
