.and_container_frame{
    border: .1px solid black;
    background: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    /*box-shadow: 3px 3px 2px gray;*/
    position:relative;
    z-index:2;
    border-radius:0rem;
}

.and_node{
    display: flex;
    flex-direction: row;
    width: 100%;
}

.and_node_nested {
    display: flex;
    flex:6;
    flex-direction: column;
    align-items: center;
    padding: 2px;
}