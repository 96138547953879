.note_box{
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid;
    /*box-shadow: 5px 5px 4px gray;*/
}


.note_title{
    display: flex;
    flex: 1;
    justify-content: center;
    /*padding: 5px;*/
}

.note_icon{
    background-color: transparent;
}
.note_icon > img {
    height: 15px;
    width: 15px;
}


.note_title_text {
    width:100%;
    flex: 3;
    display:flex;
}

.note_title_text > textarea { 
    border: none;
    text-align-last: center;
    width: 100%;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 8px;
}
