.pass-through-portal {
    pointer-events: none;
  }

  .footer-editor-drawer {
    pointer-events: all;
  }

  .side-bar-contents {
    padding: 0px 5px 0px 5px;
  }

  .footer-editor-drawer  textarea {
    text-align: left;
    overflow: auto;
    margin: 8px;
}

.footer-editor-drawer  .bp3-button-group .bp3-button {
  padding: 0px 0px 0px 0px;
  
}
.footer-editor-drawer  .bp3-button-group .bp3-button svg {
  margin: 0px 10px 0px 10px;
  
}


.footer-editor-drawer  .cheatsheet {
  height: 80vh;
  
}
.footer-editor-drawer  .cheatsheet img{
  max-height:100%;
  max-width:100%;
  display:block;
}
