@import './components/NestContainer.css';


@import './components/top_bar/top_bar.css';

@import './components/side_bar/side_bar.css';

@import './components/nodes/containers/Container.css';
@import './components/nodes/containers/ConsequenceNode.css';
@import './components/nodes/containers/EnumerationNode.css';
@import './components/nodes/containers/ExceptionNode.css';
@import './components/nodes/containers/RuleNode.css';
@import './components/nodes/containers/Note.css';

@import './components/nodes/elements/andor/and_node.css';
@import './components/nodes/elements/andor/or_node.css';
@import './components/nodes/elements/text/text_node.css';
@import './components/nodes/elements/header/header_node.css';


@import '~normalize.css/normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';

@import './components/nodes/shared/TripleCheckbox.css';
@import './components/nodes/shared/MenuButton.css';
@import './components/nodes/shared/HoverMenu.css';

body { 
  background-size: 5px 5px;
  background-image: linear-gradient(to right, #F5F8FA 1px, transparent 1px), linear-gradient(to bottom, #F5F8FA 1px, transparent 1px);
}