
.text_box_node_frame{
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 2px 0px 3px;
    height:auto;
    width:100%;
    position:relative;
    z-index: 1;
    border-radius:.5rem;
}

.text_box_node_text {
    flex:6;
}

.text_box_node_text > textarea {
    border: none;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    overflow: hidden;
    
    font-size: 8px;
}

.embedded_text_node_textarea {
    width: 100%;
    position:relative;
    z-index:1;
    display:flex;
    flex-direction: column;
}

.embedded_text_box_node_text{
    display: flex;
    background: transparent;
    flex:3;
}

.embedded_text_box_node_text > textarea {
    border: none;
    border-radius: 1rem;
    text-align: left;
    width: 100%;
    resize: none;
    overflow: hidden;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 8px;
    padding-top:3px;
    padding-left:5px;
}

.embedded_text_box_node_citation {
    display:flex;
    flex:1;
}

.embedded_text_box_node_citation > textarea{
    border: none;

    text-align: left;
    width: 100%;
    resize: none;
    overflow:hidden;
    font-size: 6px;
    font-weight: bold;
    color:gray;
    background: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    
    padding-left:5px;
    padding-top:0px;
    border-radius:.5rem;
}